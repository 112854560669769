/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2022 
	22-02-2022
	- [ADDED] New option to use this videoplayer setup with Youtube and MP4 directly
2017
	01-03-2018
	- [ADDED] Added document.ready to onready.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage

	specific_videoplayer.onready();
	
!Wishlist

	none
	
*/
  
var specific_videoplayer = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function() {
		
		$(document).ready(function() {
		
			$(document).on('click','.js-videoplayer-play',function() {

				var _category = $(this).parent().data('category');

				switch(_category) {

					case 'mp4':

						var _file = $(this).parent().data('file');
						$(this).parent().html('<iframe frameborder="0" allowfullscreen srcdoc="<html><head><style>body{margin: 0;padding: 0;}.bg-video-wrap {position: relative;overflow: hidden;width: 100%;height: 100vh;display: flex;}video {object-fit: cover;min-width: 100%;min-height: 100vh;z-index: 1;}</style></head><body><div class=\'bg-video-wrap\'><video controls loop autoplay><source src=' +  _file+ ' type=\'video/mp4\'></video></div></body></html>"></iframe>');

					break;

					case 'youtube':

						var youtube_key = $(this).parent().data('youtube-key');
						$(this).parent().html('<iframe src="https://www.youtube.com/embed/' + youtube_key + '?showinfo=0&autoplay=1&rel=0&controls=0" frameborder="0" allowfullscreen></iframe>');

					break;

				}

			});
		
		});
	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	// none

		
}).apply(specific_videoplayer); 